<template>
    <v-menu dense>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                :title="toggleVal">
                <v-icon small :class="toggleVal == null || toggleVal == 'Off' ? '' : 'success--text'">{{ icon }}</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group
                v-model="toggleVal"
                @change="update">
                <template v-for="(f, i) in dOptions">
                    <v-list-item :key="i" :value="f.value">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ f.name }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Menu-Automation',
    data: function() {
        return {
            smallOptions: [
                { name: 'Off', value: 'Off' },
                { name: 'Hourly', value: 'Hourly' },
                { name: 'Every 3 Hours', value: 'EveryThreeHours' },
                { name: 'Every 12 Hours', value: 'EveryTwelveHours' },
                { name: 'Daily', value: 'Daily' },
                { name: 'Weekly', value: 'Weekly' }
            ],
            largeOptions: [
                { name: 'Off', value: 'Off' },
                { name: 'Daily', value: 'Daily' },
                { name: 'Weekly', value: 'Weekly' },
                { name: 'Monthly', value: 'Monthly' },
                { name: 'Yearly', value: 'Yearly' }
            ],
            toggleVal: null
        }
    },
    props: {
        dailyOnly: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-timer'
        },
        isLargeRange: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: null
        },
        value: {
            Type: String,
            default: null
        },
    },
    computed: {
        dOptions() {
            if (this.options != null) {
                return this.options;
            }
                        
            if (this.dailyOnly) {
                return [{ name: 'Off', value: 'Off' }, { name: 'Daily', value: 'Daily' }];
            }

            return this.isLargeRange ? this.largeOptions : this.smallOptions;
        }
    },
    mounted() {
        if (!this.value) {
            this.toggleVal = 'Off'; //this.options[0];
        }
        else {
            this.toggleVal = this.value; //this.options.find(y => y.value == this.value);
        }
    },
    watch: {
        value: function(val) {
            if (!val) {
                this.toggleVal = 'Off';
            }
            else {
                this.toggleVal = val; //this.options.find(y => y.value == val);
            }
        }
    },
    methods: {
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>